// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "stylesheets/application.css";
import 'flowbite';
import './google_maps_autocomplete';

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

document.addEventListener("DOMContentLoaded", () => {
  // Initialize user dropdown menu
  const userMenuButton = document.getElementById('user-menu-button');
  const userDropdown = document.getElementById('user-dropdown');

  if (userMenuButton && userDropdown) {
    userMenuButton.addEventListener('click', () => {
      userDropdown.classList.toggle('hidden');
    });
  }

  // Initialize general menu dropdown
  const menuButton = document.querySelector('[data-dropdown-toggle="menu-dropdown"]');
  const menuDropdown = document.getElementById('menu-dropdown');

  if (menuButton && menuDropdown) {
    menuButton.addEventListener('click', () => {
      menuDropdown.classList.toggle('hidden');
    });
  }
});