document.addEventListener("DOMContentLoaded", function () {
  var autocompleteInput = document.getElementById('property_address');
  if (autocompleteInput) {
    // console.log('Autocomplete input found.');
    var autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
      types: ['geocode']
    });

    autocomplete.addListener('place_changed', function () {
      var place = autocomplete.getPlace();
      var latitudeInput = document.getElementById('property_latitude');
      var longitudeInput = document.getElementById('property_longitude');

      if (!latitudeInput) {
        console.error('Latitude input field is missing.');
      }
      if (!longitudeInput) {
        console.error('Longitude input field is missing.');
      }
      if (!place.geometry) {
        console.error('Place geometry is unavailable.');
      }

      if (latitudeInput && longitudeInput && place.geometry) {
        latitudeInput.value = place.geometry.location.lat();
        longitudeInput.value = place.geometry.location.lng();
        console.log('Latitude and Longitude set successfully.');
      } else {
        console.error('Latitude or Longitude input field is missing or place geometry is unavailable.');
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  var mapElement = document.getElementById('map');

  if (mapElement) {
    var latitude = mapElement.dataset.latitude;
    var longitude = mapElement.dataset.longitude;
    var mapOptions = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: 15
    };
    var map = new google.maps.Map(mapElement, mapOptions);
    new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map
    });
  }
});
